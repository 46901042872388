<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.0324 15.8231C28.0324 16.2492 27.6975 16.5946 27.2843 16.5946L4.71568 16.5946C4.30254 16.5946 3.96763 16.2492 3.96763 15.8231C3.96763 15.3971 4.30254 15.0517 4.71568 15.0517L27.2843 15.0517C27.6975 15.0517 28.0324 15.3971 28.0324 15.8231Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-primary-base)'
    }
  }
}
</script>
