<template>
  <div>
    <page-header>
      Personal settings
      <template slot="interactives">
        <v-btn @click="doLogout">
          Log out
        </v-btn>
      </template>
    </page-header>
    <dashboard-content
      :on-close="refreshUser"
    >
      <div
        v-if="user"
        class="profile"
      >
        <div class="profile-header">
          <h1 class="profile-title">
            <template
              v-if="user.title"
            >
              {{ user.title }}
            </template>
            {{ user.first_name }} {{ user.last_name }}
          </h1>
          <div
            v-if="user.organization"
            class="profile-affiliation"
          >
            {{ user.organization }}
          </div>
        </div>
        <div :class="['profile-body', { 'is-opened': value.length }]">
          <v-expansion-panels
            v-model="value"
            multiple
            flat
            accordion
          >
            <!--
              Personal Info
            -->
            <v-expansion-panel class="expansion-panel">
              <v-expansion-panel-header
                class="pa-0"
                hide-actions
              >
                <template v-slot:default="{ open }">
                  <div>
                    <div class="d-flex align-center">
                      <div class="expansion-panel-header-title">
                        Personal Info
                      </div>
                      <v-spacer />
                      <template v-if="open">
                        <icon-minus />
                      </template>
                      <template v-else>
                        <icon-plus />
                      </template>
                    </div>
                    <div class="expansion-panel-header-subtitle">
                      Change your personal info
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form
                  ref="profileForm"
                  v-model="profileFormValid"
                  class="profile-page"
                  @submit.prevent="saveProfile"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <form-select
                        :value="{ name: user.title }"
                        :items="titleItems"
                        label="Title"
                        :menu-props="{
                          offsetY: true,
                          contentClass: 'custom-select-menu'
                        }"
                        @input="updateProperty('title', $event)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <form-input
                        :value="user.organization"
                        label="Organization"
                        autocomplete="organization"
                        @input="updateProperty('organization', $event)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <form-input
                        :value="user.first_name"
                        :rules="rules.first_name"
                        label="First name"
                        autocomplete="given-name"
                        @input="updateProperty('first_name', $event)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <form-input
                        :value="user.last_name"
                        :rules="rules.last_name"
                        label="Last name"
                        autocomplete="family-name"
                        @input="updateProperty('last_name', $event)"
                      />
                    </v-col>
                    <!--                  <v-col cols="12">-->
                    <!--                    <form-input-->
                    <!--                      :value="user.speciality"-->
                    <!--                      label="Speciality"-->
                    <!--                      @input="updateProperty('speciality', $event)"-->
                    <!--                    ></form-input>-->
                    <!--                  </v-col>-->
                    <!--                  <v-col cols="12">-->
                    <!--                    <form-input-->
                    <!--                      :value="user.position"-->
                    <!--                      label="Position or title"-->
                    <!--                      @input="updateProperty('position', $event)"-->
                    <!--                    ></form-input>-->
                    <!--                  </v-col>-->
                    <!--                  <v-col cols="12">-->
                    <!--                    <form-input-->
                    <!--                      :value="user.npi"-->
                    <!--                      label="National Provider Identifier (NPI)"-->
                    <!--                      @input="updateProperty('npi', $event)"-->
                    <!--                    ></form-input>-->
                    <!--                  </v-col>-->
                    <v-col class="col-12 d-flex justify-end mt-4">
                      <v-btn
                        :disabled="!profileFormValid"
                        x-large
                        type="submit"
                        color="primary"
                        min-width="184"
                      >
                        Update
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--
              Professional Info
            -->
            <v-expansion-panel class="expansion-panel">
              <v-expansion-panel-header
                class="pa-0"
                hide-actions
              >
                <template v-slot:default="{ open }">
                  <div>
                    <div class="d-flex align-center">
                      <div class="expansion-panel-header-title">
                        Professional Info
                      </div>
                      <v-spacer />
                      <template v-if="open">
                        <icon-minus />
                      </template>
                      <template v-else>
                        <icon-plus />
                      </template>
                    </div>
                    <div class="expansion-panel-header-subtitle">
                      Change your professional info
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form
                  ref="professionalForm"
                  v-model="professionalFormValid"
                  @submit.prevent="saveProfessional"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-autocomplete
                        :value="user.specialty"
                        :items="specialties"
                        hide-no-data
                        hide-selected
                        rounded
                        label="SPECIALTY"
                        item-text="text"
                        item-value="id"
                        class="custom-autocomplete-rounded"
                        :menu-props="{
                          offsetY: true,
                          contentClass: 'custom-select-menu'
                        }"
                        @input="updateProperty('specialty', $event)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <form-input
                        :value="user.npi"
                        label="NPI"
                        @input="updateProperty('npi', $event)"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        :value="user.stateLicensed"
                        :items="states"
                        hide-no-data
                        hide-selected
                        rounded
                        label="State"
                        item-text="text"
                        item-value="id"
                        class="custom-autocomplete-rounded"
                        :menu-props="{
                          offsetY: true,
                          contentClass: 'custom-select-menu'
                        }"
                        @input="updateProperty('stateLicensed', $event)"
                      />
                    </v-col>
                    <v-col class="col-12 d-flex justify-end mt-4">
                      <v-btn
                        :disabled="!professionalFormValid"
                        x-large
                        type="submit"
                        color="primary"
                        min-width="184"
                      >
                        Update
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--
              Password
            -->
            <v-expansion-panel class="expansion-panel">
              <v-expansion-panel-header
                class="pa-0"
                hide-actions
              >
                <template v-slot:default="{ open }">
                  <div>
                    <div class="d-flex align-center">
                      <div class="expansion-panel-header-title">
                        Password
                      </div>
                      <v-spacer />
                      <template v-if="open">
                        <icon-minus />
                      </template>
                      <template v-else>
                        <icon-plus />
                      </template>
                    </div>
                    <div class="expansion-panel-header-subtitle">
                      Change your password
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form
                  ref="passwordForm"
                  v-model="passwordFormValid"
                  :lazy-validation="false"
                  @submit.prevent="updatePassword"
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <form-input
                        v-model="oldPassword"
                        :rules="rules.password"
                        validate-on-blur
                        label="Old password"
                        type="password"
                        autocomplete="current-password"
                      />
                    </v-col>
                    <v-col cols="12">
                      <form-input
                        v-model="newPassword"
                        :rules="rules.password"
                        validate-on-blur
                        label="New password"
                        type="password"
                        autocomplete="new-password"
                      />
                    </v-col>
                    <v-col class="col-12 d-flex justify-end mt-7">
                      <v-btn
                        x-large
                        type="submit"
                        color="primary"
                        min-width="184"
                      >
                        Update
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!--
              Self-service BAA
            -->
            <v-expansion-panel class="expansion-panel">
              <v-expansion-panel-header 
                class="pa-0"
                hide-actions
              >
                <template v-slot:default="{ open }">
                  <div>
                    <div class="d-flex align-center">
                      <div class="expansion-panel-header-title">
                        BAA
                      </div>
                      <v-spacer />
                      <template v-if="open">
                        <icon-minus />
                      </template>
                      <template v-else>
                        <icon-plus />
                      </template>
                    </div>
                    <div class="expansion-panel-header-subtitle">
                      Self-service Business Associate Agreement
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- BAA Form -->
                <div v-if="!showBAA">
                  <v-form
                    ref="baaForm"
                    v-model="baaFormValid"
                    :lazy-validation="false"
                    @submit.prevent="generateBAA" 
                  >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <form-input
                          v-model="baaData.entityName"
                          :rules="rules.entity_name"
                          label="Legal name of covered entity"
                          autocomplete="entity-name"
                        />
                      </v-col>
                      <v-col cols="12">
                        <form-input
                          v-model="baaData.entityKind"
                          :rules="rules.entity_kind"
                          label="Kind of entity (e.g., Limited Liability Company)"
                          autocomplete="entity-kind"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="baaData.entityState"
                          :items="states"
                          :rules="rules.entity_state"
                          filled
                          hide-no-data
                          hide-selected
                          rounded
                          label="State where covered entity was formed"
                          item-text="text"
                          item-value="id"
                          class="custom-autocomplete-rounded-lowercase"
                          :menu-props="{
                            offsetY: true,
                            contentClass: 'custom-select-menu'
                          }"
                        />
                      </v-col>
                      <v-col cols="12">
                        <form-input
                          v-model="baaData.entityEmail"
                          :rules="rules.entity_email"
                          label="Email address for communication"
                          autocomplete="off"
                        />
                      </v-col>
                      <v-col cols="12">
                        <form-input
                          v-model="baaData.partyName"
                          :rules="rules.e_signature"
                          label="Name for e-signature"
                          autocomplete="party-name"
                        />
                      </v-col>
                      <v-col cols="12">
                        <form-input
                          v-model="baaData.partyTitle"
                          :rules="rules.position_title"
                          label="Position or title within covered entity"
                          autocomplete="party-title"
                        />
                      </v-col>
                      <v-col class="col-12 d-flex justify-end mt-7">
                        <v-btn
                          x-large
                          :disabled="!baaFormValid"
                          type="submit"
                          color="primary"
                          min-width="184"
                        >
                          Generate PDF
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
                <!-- Loading spinner for pending PDF progress -->
                <Loading
                  v-else
                  :state="pdfLoadProgress < 1"
                />
                <!-- Elements for rendered PDF and controls -->
                <div v-if="showBAA">
                  <!-- PDF canvas -->
                  <pdf
                    :src="pdfUrl"
                    :page="page"
                    style="display: inline-block; right: 15%; width: 125%"
                    @num-pages="pageCount = $event"
                    @page-loaded="currentPage = $event"
                    @progress="pdfLoadProgress = $event"
                  />
                  <!-- Page, Delete, and Download buttons -->
                  <div v-if="pdfLoadProgress == 1">
                    <v-col class="col-12 d-flex justify-space-around mt-4">
                      <v-row class="col-12 justify-space-around">
                        <v-btn
                          v-if="currentPage > 1"
                          icon
                          @click="page -= 1"
                        >
                          <v-icon>{{ mdiChevronLeft }}</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          icon
                          disabled
                        >
                          <v-icon>{{ mdiChevronLeft }}</v-icon>
                        </v-btn>
                        {{ currentPage }} / {{ pageCount }}
                        <v-btn
                          v-if="currentPage < pageCount"
                          icon
                          @click="page += 1"
                        >
                          <v-icon>{{ mdiChevronRight }}</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          icon
                          disabled
                        >
                          <v-icon>{{ mdiChevronRight }}</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col class="col-12 d-flex justify-space-around mt-4">
                      <v-row class="col-12 justify-space-around">
                        <v-btn
                          x-large
                          style="background-image:none!important;color:#ff9175!important"
                          outlined
                          type="submit"
                          min-width="174"
                          @click="showBAA = false"
                        >
                          Delete
                        </v-btn>
                        <v-btn
                          x-large
                          type="submit"
                          color="primary"
                          min-width="174"
                          @click="downloadBAA"
                        >
                          Download
                        </v-btn>
                      </v-row>
                    </v-col>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </dashboard-content>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import pdf from 'vue-pdf'
import format from 'string-template'

import DashboardContent from '@/components/dashboard/DashboardContent'
import FormInput from '@/components/form/FormInput'
import FormSelect from '@/components/form/FormSelect'
import IconPlus from '@/components/icons/IconPlus'
import IconMinus from '@/components/icons/IconMinus'
import Loading from '@/views/dashboard/review/components/Loading'
import PageHeader from '@/components/dashboard/PageHeader'

import specialties from '@/kit/dictionaries/specialties'
import states from '@/kit/dictionaries/states'
import emailValidation from '@/helpers/emailValidation'

import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
  name: 'Profile',
  components: {
    DashboardContent,
    FormInput,
    FormSelect,
    IconPlus,
    IconMinus,
    Loading,
    PageHeader,
    pdf
  },
  data() {
    return {
      mdiChevronLeft,
      mdiChevronRight,
      value: [],
      oldPassword: '',
      newPassword: '',
      baaData: {
        entityName: '',
        entityKind: '',
        entityCity: '',
        entityState: '',
        entityZipcode: '',
        entityEmail: '',
        partyName: '',
        partyTitle: ''
      },

      // PDF data
      showBAA: false,
      pdfLoadProgress: 0,
      pdfUrl: '',
      currentPage: 0,
      pageCount: 0,
      page: 1,
      baaFormValid: false,

      profileFormValid: false,
      professionalFormValid: false,
      passwordFormValid: false,
      rules: {
        password: [
          v => v.length >= 6 || 'Password should be at least 6 characters'
        ],
        first_name: [v => !!v || 'First name is required'],
        last_name: [v => !!v || 'Last name is required'],
        entity_name: [v => !!v || 'Name of covered entity required'],
        entity_kind: [v => !!v || 'Entity kind required'],
        entity_state: [v => !!v || 'Entity state or region required'],
        entity_email: [
          v => !!v || 'Entity notice email required',
          v => emailValidation(v)
        ],
        e_signature: [v => !!v || 'Name for e-signature required'],
        position_title: [v => !!v || 'Position or title required']
      },
      titleItems: [
        { name: 'Dr.' },
        { name: 'Mr.' },
        { name: 'Mrs.' },
        { name: 'Ms.' },
        { name: 'Other' }
      ],
      specialties: specialties(),
      states: states()
    }
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    ...mapGetters('app', ['isLocked'])
  },
  methods: {
    ...mapActions('authentication', ['fetchUser', 'logout']),
    ...mapActions('app', ['unlock']),
    async refreshUser() {
      await this.fetchUser(this.user.id)
    },
    validate(ref) {
      this.$refs[ref].validate()
    },
    updatePassword() {
      this.validate('passwordForm')

      if (this.passwordFormValid) {
        const credentials = this.$firebase.auth.EmailAuthProvider.credential(
          this.user.email,
          this.oldPassword
        )

        this.$firebase
          .auth()
          .currentUser.reauthenticateWithCredential(credentials)
          .then(userCredential => {
            userCredential.user.updatePassword(this.newPassword).then(() => {
              this.$notify({ text: 'Password was successfully Updated.' })
              this.oldPassword = ''
              this.newPassword = ''
              this.passwordFormValid = false
            })
          })
          .catch(e => {
            this.$notify({
              type: 'err',
              text: e.message
            })
          })
      }
    },
    generateBAA() {
      this.validate('baaForm')

      if (this.baaFormValid) {
        this.pdfUrl = format(
          '{baseUrl}/baa?'+
          'entityName={entityName}&'+
          'entityType={entityKind}&'+
          'state={entityState}&'+
          'entityEmail={entityEmail}&'+
          'partyName={partyName}&'+
          'signatureTitle={partyTitle}', {
            baseUrl: process.env.VUE_APP_API_URL,
            entityName: this.baaData.entityName,
            entityKind: this.baaData.entityKind,
            entityState: this.baaData.entityState,
            entityEmail: this.baaData.entityEmail,
            partyName: this.baaData.partyName,
            partyTitle: this.baaData.partyTitle
        })
        this.showBAA = true
        this.isLoaded = true
      }
    },
    downloadBAA() {
      fetch(this.pdfUrl).then(function(t) {
        return t.blob().then((b) => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(b)
          a.setAttribute('download', 'AdherelyBAA.pdf')
          a.click()
        })
      })
    },
    deleteBAA() {
      this.showBAA = false
      this.pdfLoadProgress = 0
      this.pdfUrl = ''
      this.currentPage = 0
      this.pageCount = 0
      this.page = 1
    },
    getFormData(model, fields) {
      const data = {}
      fields.forEach(field => {
        if (undefined !== model[field]) {
          data[field] = model[field]
        }
      })

      return data
    },
    saveProfile() {
      this.validate('profileForm')
      if (this.profileFormValid) {
        this.$firebase.db
          .collection('users')
          .doc(this.user.id)
          .update(
            this.getFormData(this.user, [
              'title',
              'organization',
              'first_name',
              'last_name'
            ])
          )
          .then(() => {
            this.$notify({ text: 'User was successfully Updated.' })
          })
          .catch(e => (this.error = e))
      }
    },
    saveProfessional() {
      this.validate('professionalForm')

      if (this.professionalFormValid) {
        this.$firebase.db
          .collection('users')
          .doc(this.user.id)
          .update(
            this.getFormData(this.user, ['stateLicensed', 'npi', 'specialty'])
          )
          .then(() => {
            this.$notify({ text: 'User was successfully Updated.' })
          })
          .catch(e => (this.error = e))
      }
    },
    updateProperty(property, value) {
      this.$store.commit('authentication/updateProperty', { property, value })
    },
    async doLogout() {
      if (window.parent) {
        window.parent.postMessage('adherelySessionClosed', '*')
        console.log('window.parent: adherelySessionClosed')
      } else {
        window.postMessage('adherelySessionClosed', '*')
        console.log('window: adherelySessionClosed')
      }
      window.Intercom('shutdown')
      await this.logout()
      await this.$router.replace({ name: 'signIn' })
      // window.location.reload()
    }
  }
}
</script>

<style scoped>
.profile-page {
  margin-top: 3rem;
}
</style>