<template>
  <v-select
    class="custom-text-field"
    :value="value ? value[property] : null"
    :items="items"
    :label="label"
    :dense="dense"
    :rules="rules"
    :item-text="property"
    :return-object="returnObject"
    :hide-details="hideDetails"
    :height="height"
    filled
    :attach="attach_"
    :menu-props="menuProps"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number],
      default: '64'
    },
    property: {
      type: String,
      default: 'name'
    },
    returnObject: {
      type: [String, Boolean],
      default: false
    },
    dense: {
      type: [String, Boolean],
      default: false
    },
    hideDetails: {
      type: [String, Boolean],
      default: false
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
    attach: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    attach_() {
      return !!this.attach
    },
  }
}
</script>
