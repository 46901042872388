<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7741 1.31691C18.0753 1.01565 18.0753 0.527208 17.7741 0.225946C17.4728 -0.0753154 16.9844 -0.0753154 16.6831 0.225946L9.25 7.65903L1.81691 0.225946C1.51565 -0.0753154 1.02721 -0.0753154 0.725948 0.225946C0.424686 0.527208 0.424686 1.01565 0.725948 1.31691L8.15904 8.75L0.225946 16.6831C-0.0753152 16.9844 -0.0753152 17.4728 0.225946 17.7741C0.527208 18.0753 1.01565 18.0753 1.31691 17.7741L9.25 9.84097L17.1831 17.7741C17.4844 18.0753 17.9728 18.0753 18.2741 17.7741C18.5753 17.4728 18.5753 16.9844 18.2741 16.6831L10.341 8.75L17.7741 1.31691Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-primary-base)'
    }
  }
}
</script>
