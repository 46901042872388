<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.637 16.5946C28.063 16.5946 28.4084 16.2492 28.4084 15.8231C28.4084 15.3971 28.063 15.0517 27.637 15.0517L17.125 15.0517L17.125 4.53973C17.125 4.11369 16.7796 3.76831 16.3536 3.76831C15.9275 3.76831 15.5821 4.11369 15.5821 4.53973L15.5821 15.0517L4.36304 15.0517C3.937 15.0517 3.59161 15.3971 3.59161 15.8231C3.59161 16.2492 3.937 16.5946 4.36304 16.5946L15.5821 16.5946V27.8137C15.5821 28.2397 15.9275 28.5851 16.3536 28.5851C16.7796 28.5851 17.125 28.2397 17.125 27.8137V16.5946L27.637 16.5946Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'var(--v-primary-base)'
    }
  }
}
</script>
