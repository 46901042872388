<template>
  <v-card
    :class="['dashboard-content', { watermark }]"
    elevation="0"
  >
    <!-- <v-btn
      v-if="!hideCloseButton && !isLocked()"
      text
      outlined
      fab
      small
      class="pa-0 dashboard-content-close"
      @click="close"
    >
      <icon-close fill="var(--v-primary-base)" />
    </v-btn> -->
    <div class="dashboard-content-content">
      <slot />
    </div>
  </v-card>
</template>

<script>
import IconClose from '@/components/icons/IconClose'
import { mapGetters } from 'vuex'
const exitToAdminFromRoutes = [
  'notifications',
  'notificationShow',
  'reminders',
  'reminderShow',
  'logs',
  'logShow',
  'contacts',
  'contactShow'
]

export default {
  name: 'DashboardContent',
  components: { IconClose },
  props: {
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    watermark: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    returnTo: {
      type: String,
      default: 'dashboard'
    }
  },
  data() {
    return {
      ...mapGetters('app', ['isLocked'])
    }
  },
  methods: {
    async close() {
      if (this.onClose) {
        await this.onClose()
      }
      exitToAdminFromRoutes.includes(this.$route.name) ? 
        await this.$router.push({ name: 'admin' }) : 
        await this.$router.push({ name: this.returnTo })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../theme/functions';

.dashboard-content {
  margin-top: 3rem;
  padding: 1rem 0 0;
  position: relative;

  &-close {
    position: absolute;
    top: 3.5rem;
    right: 40px;
    z-index: 5;

    @media (max-width: 959px) {
      right: 22px;
    }
  }

  &-content {
    position: relative;
  }

  &.watermark {
    position: relative;

    @media (min-width: 600px) {
      &::before {
        content: '';
        position: absolute;
        right: -24px;
        bottom: -2px;
        width: 100%;
        max-width: 340px;
        height: 364px;
        background-image: asset('icon-grey.svg');
        background-size: contain;
        background-position: right 40px;
      }
    }
  }
}
</style>
